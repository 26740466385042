import React, {useState,useRef} from "react";
import GoogleMap from 'google-maps-react-markers'

const App = () => {
  

  const handleClick = [{
    
  }]

  return (
    <>
    <div className='mapHeader'>
    <div  className='carrycta'>
     <span style={{fontWeight:'700'}}> Carry Glacial Doom </span> <br/>
      mike@glacialdoom.com
    </div>
    <div className='logobox'>
        <img className='maplogo' width='100%' src='./assets/gd_logo.png' />
        </div>
    </div>
    <div className='mapBox'>
    <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1tB4IQcBjsPB8AY0unzV_QlKIyXIRvdk&ehbc=2E312F&noprof=1&z=10" width="100%" height="100%"></iframe>   
    </div>
    </>
    )
}

export default App