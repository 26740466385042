import './App.css';
import React from 'react';

import { BrowserRouter as Router,Route,Routes } from "react-router-dom";
import Map from './Components/Map.jsx'



function App() {
  return (
    <div>
    <Router>
      <Routes>

      <Route path='/' element={<Map></Map>}/>

      </Routes>

    </Router>
    </div>
  );
}

export default App;
